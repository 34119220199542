<script lang="ts">
export default {
	name: "HomePage"
};
</script>

<script lang="ts" setup>
import { userStore } from "@/stores/user";
import { storeToRefs } from "pinia";
import { listingsStore } from "@/stores/listings";
import { useProvideReportFilters } from '@/components/Reports/filters';
import { RouterLink } from 'vue-router';
import { vacanciesStore } from '@/stores/vacancies';

useProvideReportFilters()

const { user } = storeToRefs(userStore());
const { listings, notifications, listingsCount, basicPlanCount, fullPlanCount } = storeToRefs(listingsStore());
const { vacancies } = storeToRefs(vacanciesStore());
</script>

<template>
	<div v-if="user && user.id">
		<app-notifications :notifications="notifications"></app-notifications>

		<div class="w-svw bg-dc-grey-100 text-dc-grey-800 py-14 p-6">
      <div class="container flex flex-col md:flex-row gap-8 items-baseline justify-between">
        <div>
          <h1 class="text-5xl">Welcome, {{ user.firstname }}</h1>
        </div>
      </div>
    </div>

    <div class="section" v-if="fullPlanCount">
			<div class="container">
				<ReportsOverview>
					<template #header>
						<div class="level">
							<div class="level-left">
								<div>
									<h3 class="subtitle is-3 mb-0">Performance at a glance</h3>
									<p v-if="basicPlanCount">Showing metrics for {{ fullPlanCount }} of {{ listingsCount }} facilities</p>
								</div>
							</div>
						</div>
					</template>
				</ReportsOverview>

				<ReportsCommonButton :as="RouterLink" :to="{ name: 'reports' }" class="mt-5">
					View all performance metrics
				</ReportsCommonButton>
			</div>
		</div>

		<div class="section">
			<div class="container">
				<div class="level">
					<h3 class="subtitle is-3 level-left">Listings</h3>
				</div>

				<div class="level">
					<BulkEditBar>
						<p>Edit multiple listings</p>

						<template #after>
							<div class="ml-auto flex items-start gap-3">
								<VacanciesReconfirmBtn :user-id="user.id" />
								<VacanciesExportBtn :listings="listings" />
							</div>
						</template>
					</BulkEditBar>
				</div>

				<ListingsDatatable :listings="listings" :vacancies="vacancies" />
			</div>
		</div>

		<div v-if="!fullPlanCount" class="section">
			<div class="container">
				<ReportsUpsellCta>
					<ReportsUpsellCtaContent>
						<ReportsUpsellCtaTitle>Unlock performance metrics to drive more referrals</ReportsUpsellCtaTitle>
						<p>
							Get listing performance metrics and reports to understand hospital searches,
							improve visibility, and connect with more families and referrers.
						</p>

						<ReportsUpsellCtaButton
							:as="RouterLink"
							:to="{ name: 'reports' }"
						>
							Find out more
						</ReportsUpsellCtaButton>
					</ReportsUpsellCtaContent>

					<ReportsUpsellCtaImage src="/upsell/cta.png" alt="Unlock performance metrics to drive more referrals" />
				</ReportsUpsellCta>
			</div>
		</div>
	</div>

	<app-modal-export />
</template>

<route lang="yaml">
meta:
  fullWidth: true
</route>

<style lang="css">

.upsell-image {
	mask-image: linear-gradient(to left, rgba(0, 0, 0, 0), rgba(0, 0, 0, 1));
}
</style>